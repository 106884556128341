<template>
    <div v-if="dialogVisible" class="modal-mask policy-bill__modal">
        <div class="custom-modal-wrapper">
            <div class="custom-modal-container">
                <div class="policy-bill">
                    <div v-if="this.preloader" class="modal-preloader">
                        <div class="spinner"></div>
                    </div>
                    <a href="javascript:void(0)" @click="close()" class="hide">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 1L1 17" stroke="black" stroke-width="2"/>
                            <path d="M1 1L17 17" stroke="black" stroke-width="2"/>
                        </svg>
                    </a>
                    <div class="stepOne">
                        <div class="policy-bill__heading">Введіть ціну поліса</div>
                        <div class="policy-bill__descr">Вкажіть бажану ціну полісу</div>
                        <div class="form-group">
                            <input v-model.number.lazy="paySum" type="number" placeholder="Ціна" class="form-control">
                            <vue-slider v-model.number.lazy="paySum"
                                tooltip="none"
                                :min="minSum"
                                :max="maxSum"
                                :interval="0.01"
                            />
                        </div>
                        <div v-if="paySum < minSum" class="policy-bill__opt error">
                            Ціна полісу не може бути меншою, ніж нетто-платіж.
                        </div>
                        <div v-else-if="paySum > maxSum" class="policy-bill__opt error">
                            Ціна полісу не може бути більшою, ніж платіж вказаний в договорі.
                        </div>
                        <div v-else class="policy-bill__opt">
                            Не менше нетто-платежу та не більше повної ціни полісу.
                        </div>
                        <button :class="{'btn-blue mb-3': true, 'disabled': error}" :disabled="error" @click="getInvoice()" type="button">Згенерувати рахунок-фактуру</button>
                        <button class="btn-white" @click="close()" type="button">Скасування</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import {downloadInvoiceLegal, getPaymentInvoice} from "@/api/order";

export default {
    components: {VueSlider},
    name: 'GetInvoiceLegalOrder',
    data() {
        return {
            minSum: 0,
            maxSum: 0,
            paySum: 0,
            order: null,
            preloader: false,
            dialogVisible: false
        };
    },
    computed: {
        error() {
            return this.paySum < this.minSum || this.paySum > this.maxSum;
        }
    },
    methods: {
        async getInvoice() {
            let invoiceLegalData = {
                company: this.order.insurant.name,
                price: this.paySum,
                code: this.order.insurant.documentation?.uin,
                mtsbuCode: this.order.mtsbuCode,
            }

            let response = getPaymentInvoice(this.order.id, this.paySum, 'legal-entity-insurance');

            let params = new URLSearchParams(invoiceLegalData).toString();
            downloadInvoiceLegal(this.$store.getters.USER.id, this.order.id, params);
            this.close();
        },
        close() {
            this.dialogVisible = false;
        },
        open(order, paySum, minSum) {
            this.order = order;
            this.paySum = paySum;
            this.maxSum = parseFloat(paySum)
            this.minSum = paySum - minSum;
            this.dialogVisible = true;
        },
    }
};
</script>


<style lang="scss" scoped>
.error {
    color: #FF0000;
}
.form-group {
    margin-bottom: unset;
}
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.custom-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.custom-modal-container {
    .policy-link {
        width: 436px;
        height: 64px;
        background: #F6F7FB;
        border-radius: 5px;
        display: flex;
        @media (max-width: 992px) {
            width: 300px;
        }

        .link {
            margin: auto 0 auto 12px;
            white-space: nowrap;
            overflow: hidden;
            max-width: 67%;
            padding: 5px;
            text-overflow: ellipsis;

            @media (max-width: 992px) {
                margin: auto 0 auto 2px;
            }
        }
    }
    .message {
        width: 258px; height: 29px;font-family: Circe,serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        color: #24282C;

        @media (max-width: 992px) {
            width: 258px;
            height: 21px;
            font-size: 17px;
            line-height: 20px;
        }
    }

    .mtsbu-icon {
        margin: auto 0 auto 20px;

        @media (max-width: 992px) {
            margin: auto 0 auto 5px;
        }
    }

    @media (max-width: 992px) {
        width:358px;
    }
    width: 516px;
    height: 217px;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 0 auto;
    padding: 20px 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.custom-modal-custom-header h3 {
    margin-top: 0;
    color: #42b983;
}

.custom-modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}
.disabled {
    background: #C0C0C0;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .custom-modal-container,
.modal-leave-active .custom-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

</style>
