<template>
    <div v-if="dialogVisible" class="modal-mask payparts__modal policy-bill__modal">
        <div class="custom-modal-wrapper">
            <div class="custom-modal-container">
                <div class="policy-bill">

                    <a href="javascript:void(0)" @click="close()" class="hide">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 1L1 17" stroke="black" stroke-width="2"/>
                            <path d="M1 1L17 17" stroke="black" stroke-width="2"/>
                        </svg>
                    </a>


                    <div v-if="!this.selectPayParts" class="stepThree">
                        <div class="policy-bill__heading" style="font-size: 21px;">Оберіть кількість платежів</div>
                        <hr class="hr-grey">
                        <div>
                            <div class="form-group">
                                <label class="policy-bill__descr">Кількість платежів</label>
                                <el-select
                                    value-key="payParts"
                                    :max-height="120"
                                    :default-first-option="true"
                                    placeholder="Оберіть кількість платежів"
                                    v-model="payParts"
                                    :options="payPartsList">
                                    <el-option v-for="optionId in payPartsList"
                                               :value="optionId"
                                               :key="optionId"
                                               :label="optionId">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <a :href="paymentLink" @click="createInvoice()" target="_blank" class="btn btn-blue instant-loan">Сформувати рахунок</a>
                        <button class="btn-white" type="button" @click="close()">Скасувати</button>
                    </div>

                    <div v-if="this.preloader" class="modal-preloader">
                        <div class="spinner"></div>
                    </div>

                    <div v-if="this.paymentLink && !this.successMessage" class="stepTwo">
                        <div class="policy-bill__heading">Рахунок сформовано</div>
                        <div class="policy-bill__descr">До сплати:</div>
                        <div class="policy-bill__value" style="text-align: center" v-text="paymentSumm + ' грн'"></div>
                        <div class="link-group">
                            <input redonly="true" v-model="paymentLink" disabled type="text" class="policy-bill__link" ref="mylink">
                            <button type="button" @click="copyLink()">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 0H8C6.897 0 6 0.897 6 2V6H2C0.897 6 0 6.897 0 8V18C0 19.103 0.897 20 2 20H12C13.103 20 14 19.103 14 18V14H18C19.103 14 20 13.103 20 12V2C20 0.897 19.103 0 18 0ZM2 18V8H12L12.002 18H2ZM18 12H14V8C14 6.897 13.103 6 12 6H8V2H18V12Z" fill="#24282C"/>
                                </svg>
                            </button>
                        </div>
                        <div class="policy-bill__liqp">+ 1,5% за послуги Liqpay</div>
                        <div class="policy-bill__opt">рахунок діє до 00:00 наступного дня</div>
                        <a :href="paymentLink" @click="close()" target="_blank" class="btn btn-blue instant-loan">Перейти до сплати</a>
                        <button class="btn-white" type="button" @click="close()">Скасування</button>
                    </div>

                    <div v-if="this.successMessage" class="stepThree">
                        <span class="successMessage">Посилання на оплату успішно скопійоване!</span>
                        <button class="btn-blue" type="button" @click="close()">Закрити</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import {VMoney} from 'v-money'
import {getPaymentInvoice} from "@/api/order";

export default {
    name: 'DeclaredSuccess',
    //props: ['dataAllPrice', 'dataOrderId'],
    data() {
        return {
            paySum: 0,
            paymentLink: null,
            orderId: null,
            successMessage: false,
            preloader: false,
            selectPayParts: false,
            payParts: 2,
            payPartsList: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            usageMonths: null,
            acquiringPercent: 1.0150,
            money: {
                decimal: ',',
                thousands: ' ',
                prefix: '',
                precision: 0,
                masked: false /* doesn't work with directive */
            },
            dialogVisible: false
        };
    },
    created() {
       // this.paySum = this.dataAllPrice;
    },
    computed: {
        paymentSumm : {
            get: function() { return (this.paySum * this.acquiringPercent).toFixed(2);}
        }
    },
    methods: {
        async getInvoice() {
           // this.orderId = this.dataOrderId;
            this.preloader = true;
            let response = await getPaymentInvoice(this.orderId, this.paySum, 'instant-loan', this.payParts, this.usageMonths);
            this.paymentLink = response.data?.data?.invoiceLink;
            this.preloader = !response.data?.data?.invoiceLink;
        },
        close() {
            this.paymentLink = null;
            this.payParts = 2;
            this.dialogVisible = false;
        },
        open: function (orderId, price, usageMonths) {
            this.successMessage = false;
            this.orderId = orderId;
            this.paySum = price;
            this.dialogVisible = true;
            this.usageMonths = usageMonths;
            /*
            if(this.paymentLink == null) {
                this.getInvoice();
            }*/
        },
        createInvoice: function() {
            if (this.payParts) {
                this.selectPayParts = true;
                this.getInvoice();
            }
            //if (this.paymentLink == null) {}
        },
        copyLink(){
            this.successMessage = true;
            this.$clipboard(this.paymentLink);
        }
    }
};
</script>


<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 1999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.custom-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.custom-modal-container {
    .policy-link {
        width: 436px;
        height: 64px;
        background: #F6F7FB;
        border-radius: 5px;
        display: flex;
        @media (max-width: 992px) {
            width: 300px;
        }

        .link {
            margin: auto 0 auto 12px;
            white-space: nowrap;
            overflow: hidden;
            max-width: 67%;
            padding: 5px;
            text-overflow: ellipsis;

            @media (max-width: 992px) {
                margin: auto 0 auto 2px;
            }
        }
    }
    .message {
        width: 258px; height: 29px;font-family: Circe,serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        color: #24282C;

        @media (max-width: 992px) {
            width: 258px;
            height: 21px;
            font-size: 17px;
            line-height: 20px;
        }
    }

    .mtsbu-icon {
        margin: auto 0 auto 20px;

        @media (max-width: 992px) {
            margin: auto 0 auto 5px;
        }
    }

    @media (max-width: 992px) {
        width:358px;
    }
    width: 516px;
    height: 217px;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 0 auto;
    padding: 20px 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.custom-modal-custom-header h3 {
    margin-top: 0;
    color: #42b983;
}

.custom-modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .custom-modal-container,
.modal-leave-active .custom-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

</style>
